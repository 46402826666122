import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LinkedInRedirectHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const user = queryParams.get('user'); // Assume backend sends user details or ID

    if (token) {
      // Save token and user details in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', user); // Optional: Store user data
      localStorage.setItem('isAuthenticated', 'true');

      // Redirect to profile page
      navigate('/profile');
    }
  }, [navigate]);

  return <div>Loading...</div>; // Show a loader or message during redirection
};

export default LinkedInRedirectHandler;
