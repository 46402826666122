
import styles from './setting.module.css'
import {AiOutlineLogout} from 'react-icons/ai'

import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logout} from '../../Redux/authSlice'
import FeedbackForm from './feedback'
import ChangePasswordForm from './changePassword';

const Setting=()=>{

   
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout()); // Dispatch the logout action
      
        return <Navigate to="/" />;
    
    
    };
    

    return(
    <>
    
        <div className={styles.container}>
        <div className={styles.item} onClick={handleLogout}>
          <AiOutlineLogout />
          <div>Logout</div>
        </div>
      </div>
    
    
    <div className={styles.container}>
   
    
<FeedbackForm/>

    </div>

    <div className={styles.container}>
        <ChangePasswordForm /> {/* Include the ChangePasswordForm */}
      </div>


  
  </>
    
    
    )
}



export default Setting