import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './changePasswordForm.module.css';

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.user.token); // Get the token from Redux

  const handleChangePassword = async () => {
    setError('');
    setSuccess('');

    if (!oldPassword || !newPassword ) {
      setError('All fields are required');
      return;
    }

    // if (newPassword !== confirmPassword) {
    //   setError('New passwords do not match');
    //   return;
    // }

    try {
      setLoading(true);

      const response = await fetch('https://br-backend-server.vercel.app/api/user/change-password', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });

      const data = await response.json();

      if (response.status === 200) {
        setSuccess('Password updated successfully');
      } else {
        setError(data.message || 'Failed to update password');
      }
    } catch (err) {
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Change Password</h2>
      <input
        type="password"
        placeholder="Current Password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        className={styles.input}
      />
      <input
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        className={styles.input}
      />
      {/* <input
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        className={styles.input}
      /> */}
      {error && <div className={styles.error}>{error}</div>}
      {success && <div className={styles.success}>{success}</div>}
      <button
        onClick={handleChangePassword}
        className={styles.button}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Change Password'}
      </button>
    </div>
  );
};

export default ChangePasswordForm;
