import React, { useState, useEffect } from 'react';
import styles from './profile.module.css';
import { BiEdit } from 'react-icons/bi';
import EditProfile from './edit-profile';
import SharedArticles from '../../components/posts/blog/shareArticleTl';
import SharedJobs from '../../components/posts/Job/shareJobTl';
import SharedIssues from '../../components/posts/issue/shareIssueTl';
import SharedLearn from '../../components/posts/media/sharedLearnTl';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfile, uploadProfilePicture, fetchUserProfile } from '../../Redux/profileSlice';
import { useParams } from 'react-router-dom';

const Profile = () => {
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { userId } = useParams(); // Grab userId from route params if available

  const dispatch = useDispatch();

  const loggedInProfile = useSelector((state) => state.profiles.profiles); // Logged-in user profile
  const userProfile = useSelector((state) => state.profiles.userProfile); // Fetched profile by userId

  // Determine which profile to display based on userId presence
  const profile = userId ? userProfile : loggedInProfile;

  const sharedArticles = profile?.sharedArticles;
  const sharedJobs = profile?.sharedJobs;
  const sharedIssues = profile?.sharedIssues;
  const sharedLearns = profile?.sharedLearns;

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserProfile(userId)); // Fetch another user's profile
    } else {
      dispatch(fetchProfile()); // Fetch the logged-in user's profile
    }
  }, [userId, dispatch]);

  const handleModal = () => setModal(!modal);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleProfilePictureUpload = () => {
    if (selectedFile) {
      const profileId = profile._id;
      dispatch(uploadProfilePicture({ profileId, file: selectedFile }));
    }
  };

  if (!profile || Object.keys(profile).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      {modal ? <EditProfile closemodal={handleModal} /> : ""}
      <div className={styles.cover}>
        <img src="https://images.pexels.com/photos/7245333/pexels-photo-7245333.jpeg" alt="cover" />
      </div>
      <div className={styles.card}>
        <div className={styles.col}>
          <div style={{ fontWeight: 600 }}>{profile.firstName} {profile.lastName}</div>
          <div>{profile.designation}</div>
          <div>{profile.company}</div>
          <div>{profile.location}</div>
        </div>
        <div style={{ marginLeft: "110px" }} className={styles.col}>
          {!userId && <div onClick={handleModal}><BiEdit /> Edit Profile</div>}
          {/* <div>5 years + Experience</div>
          <div>M.Tech from CU</div> */}
        </div>
      </div>
      <div className={styles.pp}>
        <img
          src={profile.profilePicture || "https://randomuser.me/api/portraits/men/28.jpg"}
          alt="Profile"
        />
      </div>
      {!userId && (
        <div className={styles.btnUpload}>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <button onClick={handleProfilePictureUpload}>Upload Profile Picture</button>
        </div>
      )}

      <div className={styles.right}>
        <div className={styles.cont}>
          <div className={styles.rcol}>
            <h3>Experience</h3>
            {profile.experience?.map((exp, index) => (
              <div key={index} className={styles.rcolItem}>
                <div className={styles.title}>{exp.title}</div>
                <div className={styles.dates}>
                  {exp.startDate ? new Date(exp.startDate).getFullYear() : 'N/A'} - 
                  {exp.endDate ? new Date(exp.endDate).getFullYear() : 'Present'}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.rcol}>
            <h3>Education</h3>
            {profile.education?.map((edu, index) => (
              <div key={index} className={styles.rcolItem}>
                <div className={styles.title}>{edu.title}</div>
                <div className={styles.dates}>
                  {edu.startDate ? new Date(edu.startDate).getFullYear() : 'N/A'} - 
                  {edu.endDate ? new Date(edu.endDate).getFullYear() : 'Present'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <h3>Shared Articles</h3>
        <SharedArticles sharedArticles={sharedArticles} />
      </div>
      <div className={styles.section}>
        <h3>Shared jobs</h3>
        <SharedJobs sharedJobs={sharedJobs} />
      </div>
      <div className={styles.section}>
        <h3>Shared issues</h3>
        <SharedIssues sharedIssues={sharedIssues} />
      </div>
      <div className={styles.section}>
        <h3>Shared learnings</h3>
        <SharedLearn sharedLearns={sharedLearns} />
      </div>
    </div>
  );
};

export default Profile;

// import React, { useState, useEffect } from 'react';
// import styles from './profile.module.css';
// import { BiEdit } from 'react-icons/bi';
// import EditProfile from './edit-profile';
// import JobTl from '../../components/posts/Job/jobtl';
// import IssueTl from '../../components/posts/issue/issueTl';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchProfile ,uploadProfilePicture,fetchUserProfile} from '../../Redux/profileSlice';
// import SharedArticles from '../../components/posts/blog/shareArticleTl';
// import SharedJobs from '../../components/posts/Job/shareJobTl'
// import SharedIssues from '../../components/posts/issue/shareIssueTl';
// import SharedLearn from '../../components/posts/media/sharedLearnTl';
// import { RiTranslate } from 'react-icons/ri';
// import { useParams } from 'react-router-dom';
// const Profile = () => {
//   const [modal, setModal] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null); // For file input
//   const profile = useSelector((state) => state.profiles.profiles);
//   const profiles = useSelector((state) => state.profiles);
//   const sharedArticles = useSelector((state) => state.profiles.profiles.sharedArticles);
//   const sharedJobs = useSelector((state) => state.profiles.profiles.sharedJobs);
//   const sharedIssues = useSelector((state) => state.profiles.profiles.sharedIssues);
//   const sharedLearns = useSelector((state) => state.profiles.profiles.sharedLearns);
//   const { articles, status, totalPages, currentPage } = useSelector((state) => state.articles);

// console.log(articles,'test')
//   const dispatch = useDispatch();


//   const handleModal = () => setModal(!modal);
// const userId = profile.userId

//   useEffect(() => {
//     dispatch(fetchProfile());
//   }, [dispatch]);


//   useEffect(() => {
//     dispatch(fetchUserProfile(userId));
//   }, [userId, dispatch]);



//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const handleProfilePictureUpload = () => {
//     if (selectedFile) {
//       const profileId = profile._id; // Assuming profile ID is available
//       dispatch(uploadProfilePicture({ profileId, file: selectedFile }));
//     }
//   };









//   if (!profile || Object.keys(profile).length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className={styles.container}>
//       {modal ? <EditProfile closemodal={handleModal} /> : ""}
//       <div className={styles.cover}>
//         <img src="https://images.pexels.com/photos/7245333/pexels-photo-7245333.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="cover" />
//       </div>
//       <div className={styles.card}>
//         <div className={styles.col}>
//           <div style={{ fontWeight: 600 }}>{profile.firstName} {profile.lastName}</div>
//           <div>{profile.designation}</div>
//           <div>{profile.company}</div>
//           <div>{profile.location}</div>
//         </div>
//         <div style={{ marginLeft: "110px" }} className={styles.col}>
//           <div onClick={handleModal}><BiEdit /> Edit Profile</div>
//           <div>5 years + Experience</div>
//           <div>M.Tech from CU</div>
//         </div>
//       </div>
//       <div className={styles.pp}>
      

//       {profile && profile.profilePicture ? (
//                     <img
//                         src={profile.profilePicture}
//                         alt="Profile"
//                     />
//                 ) : (
//                     <img
//                       src="https://randomuser.me/api/portraits/men/28.jpg"
//                         alt="Default Profile"
//                     />
//                 )}
           





//       </div>


//       <div className={styles.btnUpload}>
//           <input type="file" accept="image/*" onChange={handleFileChange} />
//           <button onClick={handleProfilePictureUpload}>Upload Profile Picture</button>
//         </div>



//       <div className={styles.right}>

//       <div className={styles.cont}>
//   <div className={styles.rcol}>
//     <h3>Experience</h3>
//     {profile.experience && profile.experience.map((exp, index) => (
//       <div key={index} className={styles.rcolItem}>
//         <div className={styles.title}>{exp.title}</div>
//         <div className={styles.dates}>
//           {exp.startDate ? new Date(exp.startDate).getFullYear() : 'N/A'} - 
//           {exp.endDate ? new Date(exp.endDate).getFullYear() : 'Present'}
//         </div>
//       </div>
//     ))}
//   </div>
//   <div className={styles.rcol}>
//     <h3>Education</h3>
//     {profile.education && profile.education.map((edu, index) => (
//       <div key={index} className={styles.rcolItem}>
//         <div className={styles.title}>{edu.title}</div>
//         <div className={styles.dates}>
//           {edu.startDate ? new Date(edu.startDate).getFullYear() : 'N/A'} - 
//           {edu.endDate ? new Date(edu.endDate).getFullYear() : 'Present'}
//         </div>
//       </div>
//     ))}
//   </div>
// </div>

      
//       </div>
//       <div className={styles.section}>
//         <h3>Shared Articles</h3>
//         <SharedArticles sharedArticles={sharedArticles} />
//       </div>

//       <div className={styles.section}>
//         <h3>Shared jobs</h3>
//         <SharedJobs sharedJobs={sharedJobs} />
//       </div>


      
//       <div className={styles.section}>
//         <h3>Shared issue</h3>
//         <SharedIssues sharedIssues={sharedIssues} />
//       </div>
    

//       <div className={styles.section}>
//         <h3>Shared learn</h3>
//         <SharedLearn sharedLearns={sharedLearns} />
//       </div>


//     </div>
//   );
// };

// export default Profile;


// import React, { useState, useEffect } from 'react';
// import styles from './profile.module.css'
// import { BiEdit } from 'react-icons/bi'
// import EditProfile from './edit-profile';
// import JobTl from '../../components/posts/Job/jobtl';
// import IssueTl from '../../components/posts/issue/issueTl';
// import { useSelector, useDispatch } from 'react-redux';
// import {fetchProfile} from '../../Redux/profileSlice'
// import SharedArticles from '../../components/posts/blog/shareArticleTl';
// const Profile = () => {
    
//     const [modal, setModal] = useState(false)
//     const profile = useSelector((state) => state.profiles.profiles);
//     const dispatch = useDispatch();
//     const handleModal = () => setModal(!modal);
    
   

//     console.log(profile);
//     useEffect(() => {
//         // Fetch profile data here using an action creator if needed
 
//         // if (!profile || profile.length === 0) {
//         //     return <div>Loading...</div>;
//         // }
//             dispatch(fetchProfile());
          
        
//     }, [dispatch]);

//     return (
//         <div className={styles.container}>
//             {modal ?
//                 <EditProfile
//                     closemodal={() => handleModal()}
//                 /> : ""}
//             <div className={styles.cover}>
//                 <img src="https://images.pexels.com/photos/7245333/pexels-photo-7245333.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" />
//             </div>
//             <div className={styles.card}>
//                 <div className={styles.col}>
//                     <div style={{ fontWeight: 600 }}>{profile.firstName} {profile.lastName}</div>
//                     <div>{profile.designation}</div>
//                     <div>{profile.company}</div>
//                     <div>{profile.location}</div>
//                 </div>
//                 <div style={{ marginLeft: "110px" }} className={styles.col}>
//                     <div onClick={() => handleModal()} > <BiEdit /> Edit Profile</div>
//                     <div>5 year + Experience </div>
//                     <div>M.Tech from CU</div>
//                 </div>
//             </div>
//             <div className={styles.pp}>
//                 <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
//             </div>
//             <div className={styles.right}>
//                 <div className={styles.rcol}>
//                     <h3>Experience</h3>
//                     {profile.experience && profile.experience.map((exp, index) => (
//                         <div key={index} className={styles.rcolItem}>
//                             <div>{exp.title}</div>
//                             <div style={{ fontSize: 'small', color: '#464646' }}>{exp.startDate}-{exp.endDate}</div>
//                         </div>
//                     ))}
//                 </div>
//                 <div className={styles.rcol} >
//                     <h3>Education</h3>
//                     {profile.education && profile.education.map((edu, index) => (
//                         <div key={index} className={styles.rcolItem}>
//                             <div>{edu.title}</div>
//                             <div style={{ fontSize: 'small', color: '#464646' }}>{edu.startDate}-{edu.endDate}</div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <SharedArticles/>
//             <JobTl />
//             <IssueTl />
//         </div>
//     )
// }

// export default Profile;




// import React, { useState } from 'react';
// import styles from './profile.module.css'
// import {BiEdit} from 'react-icons/bi'
// import EditProfile from './edit-profile';
// import JobTl from '../../components/posts/Job/jobtl';
// import IssueTl from '../../components/posts/issue/issueTl';



// const Profile =()=>{
//     const [modal,setmodal]=useState(false)
    
// const handlemodal = () => setmodal(!modal);


//     return(
//         <div className={styles.container}>
       
    
//           {modal?
//           <EditProfile
//           closemodal={()=>handlemodal()}
//           />:""}
//           <div className={styles.cover}>
//               <img src="https://images.pexels.com/photos/7245333/pexels-photo-7245333.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" />
//           </div>
       
//         <div className={styles.card}>
//             <div className={styles.col}>
//                 <div style={{fontWeight:600}}>Zack Haren</div>
//                 <div>BIM Technician</div>
//                 <div>BIM rely</div>
//                 <div>New Delhi</div>
//             </div>
//             <div style={{marginLeft:"110px"}} className={styles.col}>
//             <div onClick={()=>handlemodal()} > <BiEdit/> Edit Profile</div>
//             <div >5 year + Experience </div>
//             <div>M.Tech from CU</div>

//             </div>
//             </div>
//             <div  className={styles.pp}>
//         <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"/>
//         </div>
//         <div className={styles.right}>
                    
//                     <div className={styles.rcol}>
//                     <h3>Experiance</h3>
//                     <div className={styles.rcolItem} >
//                     <div>Memaat Associates</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2018-2020</div>
//                     </div>
//                     <div className={styles.rcolItem} >
//                     <div>AEC Digital Solution</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2017-2018</div>
//                     </div> 
//                     <div className={styles.rcolItem} >
//                     <div>Archohm Consultansts</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2015-2017</div>
//                     </div>
//                     </div>
//                     <div className={styles.rcol} >
//                     <h3>Education</h3>
//                     <div className={styles.rcolItem} >
//                     <div>Chandigarh university - M.tech</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2016-2018</div>
//                     </div> 
//                     <div className={styles.rcolItem} >
//                     <div>Jamia Millia Islamia - B.tech</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2013-2016</div>
//                     </div> 
//                     <div className={styles.rcolItem} >
//                     <div>Abul kalam university - Diploma</div>
//                     <div style={{fontSize:'small',color:'#464646'}}>2010-2013</div>
//                     </div> 
//                     </div>
//                 </div>
//                 <JobTl/>
//                 <IssueTl/>
              



//         </div>
//     )
// }

// export default Profile